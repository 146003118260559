.settingsBox {
  > a {
    margin: 1rem 0;
    margin-right: 1rem;
  }
}

.iconWrapper {
  border-radius: 50px;
  height: 50px;
  width: 50px;
  background-color: #d0ffff;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  > i {
    font-size: 20px;
  }
}
