#dashboard {
  .box-panel {
    div[class^='col-'] {
      margin-bottom: 1rem;

      .card {
        height: 100%;
      }
    }
  }
}
