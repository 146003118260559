@import '../../_theming';

$side-nav-width: 272px;

.nav-right {
  position: fixed;
  display: block;
  top: 0;
  right: auto;
  min-width: $side-nav-width;
  padding: 12px;
  height: 100vh;
  min-height: 100%;
  box-shadow: rgba(0, 0, 0, 0.05) -4px 0px 20px inset;
  background-color: #19212e;
  @include transition;
  .nav-brand {
    align-items: center;
    border-bottom: 1px solid #2d3f5c;
    padding: 6px 0;
    margin-top: -12px;
    .img-wrap {
      background-color: #19212e;
      height: 45px;
      width: 45px;
      border-radius: 50%;
      line-height: 45px;
      text-align: center;
    }
  }
  .nav-links {
    padding: 12px 0;
    height: calc(100vh - 102px);
    overflow-y: scroll;
    > li {
      a,
      button {
        border-width: 0;
        background: transparent;
        outline: 0;
        padding: 8px 12px;
        display: block;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 700 !important;
        margin-bottom: 8px;
        color: #737e8c;
        .s-icon {
          display: inline-block;
          width: 15px;
          height: 15px;
          margin-right: 8px;
          i {
            color: #737e8c;
          }
        }

        &.active {
          color: #fff;
          background-color: #2d3f5c;
          .s-icon {
            i {
              color: #fff;
            }
          }
          & ~ .dropdown {
            height: 100%;
          }
        }
        &:hover {
          background-color: #2d3f5c;
        }
      }
      .dropdown {
        height: 0;
        overflow: hidden;
        margin-left: 12px;
        margin-top: 8px;
        @include transition;
        a,
        button {
          color: #000;
        }
      }
    }
  }
}
._main_panel {
  float: right;
  width: calc(100% - #{$side-nav-width});
  // padding-bottom: 5rem;
  @include transition;
  .nav-top {
    padding: 8px;
    justify-content: space-between;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    .hamburger {
      padding: 6px;
      display: none;
      svg {
        height: 1.5rem;
        width: 1.5rem;
        fill: currentColor;
        vertical-align: middle;
        transform: scaleX(-1);
      }
    }
    .nav-links {
      > li {
        a,
        button {
          // padding: 4px 12px;
          // border-radius: 5px;
          font-size: 13px;
          // color: rgb(72, 72, 72);
          font-weight: 700;
          // &:hover {
          //   background-color: hsla(0, 0%, 100%, 0.08);
          // }
        }
      }
    }
  }
  ._bod_wrap {
    // padding: 0 20px;
    ._bod_content {
      padding: 3rem 0;
      position: relative;
      min-height: calc(100vh - 59px);
    }
  }
}

@media screen and (max-width: 991px) {
  .nav-open .nav-right {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -o-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
  .nav-right {
    transform: translate3d(-#{$side-nav-width}, 0, 0);
  }
  .nav-open ._main_panel {
    width: calc(100% - #{$side-nav-width});
  }
  ._main_panel {
    width: 100%;
    .nav-top {
      .hamburger {
        display: block;
      }
    }
  }
}
