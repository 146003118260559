@import './theming';

// https://react-bootstrap.netlify.app/getting-started/introduction/#customize-bootstrap
$theme-colors: (
  'primary': $primary-color,
  'secondary': $secondary-color,
  'info': $info-color,
  'danger': $danger-color,
  'success': $success-color,
  'warning': $warning-color,
  'dark': $dark-color,
  'light': $light-color
);
ol,
ul,
dl {
  margin-bottom: 0 !important;
}
.container {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
.btn-link,
.btn-link:focus,
.btn-link.focus,
.btn-link:hover {
  text-decoration: none !important;
}
.btn {
  font-weight: 500 !important;
}
.bg-danger {
  color: #fff;
}
.btn-white {
  background-color: #fff !important;
  &.outline {
    border-color: #dfdfdf;
  }
}
.btn-chromeless {
  border-width: 0;
  background: transparent;
  font-size: inherit;
  padding: 0;
  outline: 0;
  cursor: pointer;
}
.card {
  color: initial;
}
.toast-header {
  background-color: #fff;
}
.toast-body {
  background-color: #fff;
}
.hr {
  opacity: 0.2 !important;
  margin: 2rem 0 !important;
}
/**
* TABLE
*/
.table > :not(:first-child) {
  border-top: none !important;
}
.table > :not(caption) > * > * {
  padding: 0.75rem !important;
}
.table-light {
  --bs-table-bg: $light-color !important;
  background-color: $light-color !important;
}
.table thead th {
  border-bottom: none !important;
}
.table-responsive {
  border-radius: 4px;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.08);
}
.table {
  font-size: 0.92rem;
  margin-bottom: 0 !important;
  background-color: #fff;
  &.table-dark {
    background-color: #343a40;
  }
  tr.success {
    background-color: #dff0d8;
  }
  th,
  td {
    vertical-align: middle;
  }
}

svg {
  overflow: initial !important;
}
@import '~bootstrap/scss/bootstrap';

/*
* Global CSS
*/
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
html,
body {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: $font-stack;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  color: #2c3543;
  line-height: 1.5;
  background-color: #fafafb;
  letter-spacing: 0.3px;
  -moz-osx-font-smoothing: grayscale;
}
p,
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}
section {
  padding: 2rem 0;
}
a {
  color: inherit;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}
a.link,
button.link {
  color: #277bba;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
button {
  box-shadow: none !important;
}
.page-title {
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 32px;
}
.paragraph {
  white-space: pre-line;
}
.head-panel {
  justify-content: space-between;
  margin-bottom: 20px;
  flex-wrap: wrap;
  width: 100%;
}
.img-cover {
  height: 100%;
  width: 100%;
  position: static;
  object-fit: cover;
}
.absolute-wrap {
  position: absolute !important;
  top: 0px !important;
  bottom: 0px !important;
  left: 0px !important;
  right: 0px !important;
}
.d-flex {
  display: flex;
}
.pagination {
  margin-top: 1rem;
  &.center {
    justify-content: center;
  }
}
ul.neutralize {
  padding: 0;
  li {
    list-style-type: none;
  }
}
.toast-container {
  z-index: 10000000;
}
/**
* Form
*/
.form-floating > label {
  opacity: 0.65;
}
.form-group-inline {
  display: flex;
  .form-group {
    margin-right: 4px;
    flex-grow: 1;
  }
}
.form-group {
  margin-bottom: 1rem;
  &:last-of-type {
    margin-bottom: 0 !important;
  }
}
.form-control:focus,
.form-select:focus {
  box-shadow: none;
}
label {
  font-weight: 700;
  margin-bottom: 2px;
}
textarea {
  min-height: 100px !important;
}
/**
* Helper classes
*/
.content-head {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
  padding-bottom: 1rem;
  &.linear {
    display: block;
  }
  h1 {
    font-size: 1.5rem;
  }
}
.section-title {
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 1rem;
}
.body-card {
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
  padding: 15px;
  background-color: #fff;
  position: relative;
}
.recharts-label {
  font-weight: 500;
}
.clickable {
  cursor: pointer;
}
.text-preline {
  white-space: pre-line;
}
.recharts-legend-item-text {
  text-transform: capitalize;
}
.box-icon {
  line-height: 50px;
  font-size: 1.7rem;
  margin-right: 1rem;
}
.add-transition {
  @include transition;
}
.blink {
  animation: blink 1s linear infinite;
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
/*
* Spinner
*/
.spi {
  border: 2px solid #000;
  border-bottom: 2px solid transparent;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  margin: 0 auto;
  background: transparent;
  -webkit-animation: spin 0.6s linear infinite;
  -moz-animation: spin 0.6s linear infinite;
  animation: spin 0.6s linear infinite;
  &.spi-light {
    border-color: #fff;
    border-bottom-color: transparent;
  }
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
