$font-stack: 'Roboto', sans-serif;

$primary-color: #0071e2;
$secondary-color: #0cc;
$info-color: #0dcaf0;
$danger-color: #dc3545;
$success-color: #1c8534;
$warning-color: #c99700;
$dark-color: #191c24;
$light-color: #f3f3f3;

@mixin box-shadow {
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
}

@mixin transition {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -ms-transition: all 0.3s;
  transition: all 0.3s;
}
