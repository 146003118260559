.rsvStatus {
  .rsvStatusBox {
    color: #000;
    display: flex;
    height: 110px;
    width: 320px;
    margin: 0 auto;
    > div {
      width: 50%;
      position: relative;
      .boxRightContent {
        border-top: 2px solid;
        border-left: 2px solid;
        border-color: #c0c0c0;
        color: #434343;
        position: absolute;
        font-size: 0.85rem;
        top: 0;
        left: -1px;
        right: -1px;
        bottom: 0;
        line-height: 20px;
        text-transform: uppercase;
        display: flex;
        align-items: baseline;
        text-align: right;
        > i {
          margin-right: 8px;
        }
        p {
          width: 100%;
        }
      }
      .middleDot {
        position: absolute;
        top: -7px;
        left: -7px;
        background: #8a8a8a;
        height: 14px;
        width: 14px;
        border-radius: 50%;
        z-index: 10;
      }
      .cornerDot {
        position: absolute;
        top: -9px;
        right: -11px;
        background-color: rgba(150, 150, 150, 0.8);
        height: 20px;
        width: 20px;
        border-radius: 50%;
        z-index: 11;
      }
      .cornerDotInner {
        position: absolute;
        top: -4px;
        right: -6px;
        background: #4e4e4e;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        z-index: 10;
      }
    }
  }
  > :nth-child(even) {
    .boxRight {
      order: 1;
      .middleDot {
        left: auto;
        right: -7px;
      }
      .cornerDot {
        right: auto;
        left: -11px;
      }
      .cornerDotInner {
        right: auto;
        left: -6px;
      }
      .boxRightContent {
        border-right: 2px solid;
        border-left: none !important;
        border-color: #c0c0c0;
        text-align: left;
      }
    }
    .boxLeft {
      order: 2;
    }
  }
  > :last-child {
    height: 2rem;
    .boxRight .boxRightContent {
      border-right: none !important;
      border-left: none !important;
    }
  }
}
